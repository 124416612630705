import {
  DigimapViewer,
  TDGMMapViewerOptions,
} from '@digimap/digimap-core-viewer';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function App() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const options: TDGMMapViewerOptions = {
      id: searchParams.get('venue') || '',
      apiKey: searchParams.get('key') || '',
      draftMode: (searchParams.get('type') || 'public') === 'draft',
      debugMode: searchParams.get('debug') === 'true',
    };
    DigimapViewer.init(options);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }} id="digimap-viewer"></div>
  );
}
